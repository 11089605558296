function bs_input_file() {
	$(".input-file").before(
		function () {
			if (!$(this).prev().hasClass('input-ghost')) {
				var element = $("<input type='file' class='input-ghost' id='fileInput' style='visibility:hidden; height:0'>");
				element.attr("name", $(this).attr("name"));

				element.change(function (e) {
					element.next(element).find('input').val((element.val()).split('\\').pop());
					//$('#blah').attr('src',(element.val()).split('\\').pop());

					$('#blah').attr('src', e.target.result);


					//reader.readAsDataURL(element.files[0]);
				});
				$(this).find("a.lab-choose").click(function () {

					element.click();

				});
				$(this).find("a.lab-reset").click(function () {
					element.val(null);
					$(this).parents(".input-file").find('input').val('');
				});
				$(this).find('input').css("cursor", "pointer");
				$(this).find('input').mousedown(function () {
					$(this).parents('.input-file').prev().click();
					return false;
				});
				return element;
			}
		}
	);
}
